import { CustomPage } from '../model';
import { getAPILocale } from '@/utils';
import { menuFragment } from '@/utils/graphql/menuFragment';
import { cropMediaFragment } from '@/utils/graphql/cropMediaFragment';
import { graphql } from 'gql.tada';

export const pagesVariables = ({
  pageType,
  slug,
  channelId,
  pageLayoutIdentifier,
  languageCode,
}: {
  pageType?: CustomPage['pageType'];
  slug?: string;
  channelId?: string;
  pageLayoutIdentifier?: CustomPage['pageLayoutIdentifier'];
  languageCode?: string;
}) => {
  const where: any = {
    platform: {
      equals: 'website',
    },
  };
  if (pageLayoutIdentifier) {
    where.pageLayoutIdentifier = {
      equals: pageLayoutIdentifier,
    };
  }
  if (channelId) {
    where.channel = {
      equals: channelId,
    };
  }
  if (pageType) {
    where.pageType = {
      equals: pageType,
    };
  }
  if (slug) {
    where.slug = {
      equals: decodeURIComponent(slug),
    };
  }
  return {
    where: where,
    locale: getAPILocale(languageCode) ?? 'en',
  };
};

// @ts-ignore
export const GET_PAGES = graphql(`
  query GetAllPages($where: Page_where, $locale: LocaleInputType) {
    Pages(where: $where, locale: $locale) {
      docs {
        id
        name
        slug
        pageLayout {
          name
          identifier
        }
        metadata {
        subTypes
          topic {
            title
            description
            id
            parentTopic {
              name
              id
            }
          }
        }
        pageLayoutIdentifier
        sections {
          isEnabled
          name
          blocks {
            id
            name
            blockLabel
            backgroundColor
            blockElements {
              type
              subTypes
              id
              title
              topics {
                id
                title
              }
              tags
              dateFilter
              dateFromFilter
              dateToFilter
              filterContentBy
              coverMedia {
                media{
                  ${cropMediaFragment}
                }
                }
              content {
                status
                id
                description
                title
                type
                subType
                slug
                coverMedia {
                  ${cropMediaFragment}
                }
                coverImage {
                  ${cropMediaFragment}
                }
                media {
                  ${cropMediaFragment}
                }
                audioNarration
                duration
                readingTime {
                  text
                }
                primaryTopics {
                  id
                  title
                  page {
                   name
                   slug
                  }
                }
                topics {
                  id
                  title
                }
                author {
                  id
                  name
                  profileUrl
                  profilePicture {
                    url
                    type
                    caption
                    mimeType
                    playlistJson
                    thumbnailUrl
                  }
                }
                  otherAuthors {
                  id
                  name
                  profileUrl
                  profilePicture {
                    url
                    type
                    caption
                    mimeType
                    playlistJson
                    thumbnailUrl
                  }
                }
                backgroundColor
                tvShow{
                  id
                  title
                  description
                  coverImage{
                    ${cropMediaFragment}
                  }
                }
                websiteBlockDetails {
                  contentMetadata {
                    id
                    platform
                    title
                    description
                    authorAvatarURL {
                      url
                      caption
                      caption
                      playlistJson
                    }
                    authorName
                    liveStream {
                      title
                      description
                      streamUrl
                    }
                    medias {
                      media {
                        ${cropMediaFragment}
                      }
                    }
                  }
                }
              }
              isPinned
              liveStream {
                 streamUrl
                  title
                  type
                  createdAt
                  description
                  epgUrl
              }
              liveCoverage {
                status  
                id
                title
                description
                updatedAt
                details
                mainHeadline
                media {
                  ${cropMediaFragment}
                }
              }
              
            }
            blockLayoutIdentifier
            blockLayout {
              name
              identifier
            }
          }
          sectionLayoutIdentifier
        }
        supportedPageLayouts
        order
        description
      ${menuFragment}
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`);
