export const cropMediaFragment = `
                    media {
                      url
                      type
                      mimeType
                      playlistJson
                      thumbnailUrl
                      thumbnailURL
                      originalUrl
                      optimizedThumbnailUrl
                      width
                      height
                    }
                    cropDetails {
                     optimizedUrl
                     type
                     url
                    }
`;
