import { getAPILocale } from '@/utils';
import { cropMediaFragment } from '@/utils/graphql/cropMediaFragment';
import { gql } from '@apollo/client';

export const contentsVariables = (
  slug: any,
  languageCode: string = 'en',
  page?: number,
  draft?: boolean,
  limit?: number,
) => {
  const data: any = {
    where: {
      slug: {
        equals: slug,
      },
    },
    locale: getAPILocale(languageCode),
  };
  if (page) {
    data.page = page;
  }
  if (limit) {
    data.limit = limit;
  }
  if (draft) {
    data.draft = true;
  }
  return data;
};

export const contentsLoadMoreVariables = ({
  existingIds,
  languageCode,
  channelId,
  page = 0,
  primaryTopicId,
  draft,
  limit = 10,
}: {
  existingIds: string[];
  languageCode: string;
  channelId?: string;
  page: number;
  primaryTopicId?: string;
  draft?: boolean;
  limit?: number;
}) => {
  const data: any = {
    where: {
      AND: [
        {
          type: {
            equals: 'article',
          },
        },
        {
          package: {
            exists: true,
          },
        },
        {
          status: {
            in: ['published', 'changed'],
          },
        },
        {
          OR: [
            {
              primaryTopics: {
                in: [primaryTopicId],
              },
            },
            {
              secondaryTopics: {
                in: [primaryTopicId],
              },
            },
          ],
        },
        {
          OR: [
            {
              translatedToChannels: {
                equals: channelId,
              },
            },
            {
              channel: {
                equals: channelId,
              },
            },
          ],
        },
        {
          id: {
            not_in: existingIds,
          },
        },
      ],
    },
    locale: getAPILocale(languageCode),
    page,
    limit,
  };

  if (draft) {
    data.draft = true;
  }
  return data;
};

export const tvShowContentsVariables = (
  slug: any,
  languageCode: string = 'en',
  page?: number,
  limit?: number,
) => {
  const data: any = {
    where: {
      AND: [
        {
          status: {
            in: ['published', 'changed'],
          },
        },
        {
          subType: {
            in: ['tvContent'],
          },
        },
        {
          tvShow: {
            equals: slug,
          },
        },
      ],
    },
    locale: getAPILocale(languageCode),
  };
  if (page) {
    data.page = page;
  }
  if (limit) {
    data.limit = limit;
  }
  return data;
};

export const contentsArticleSiteMapVariables = (
  where: any,
  languageCode: string = 'en',
  page?: number,
  draft?: boolean,
  limit?: number,
) => {
  const data: any = {
    where,
    locale: getAPILocale(languageCode),
  };
  if (page) {
    data.page = page;
  }
  if (limit) {
    data.limit = limit;
  }
  if (draft) {
    data.draft = true;
  }
  return data;
};

export const contentsAuthorVariables = (
  author: any,
  languageCode: string = 'en',
  page?: number,
  draft?: boolean,
  limit?: number,
) => {
  const data: any = {
    where: {
      author: {
        equals: author,
      },
    },
    locale: getAPILocale(languageCode),
  };
  if (page) {
    data.page = page;
  }
  if (limit) {
    data.limit = limit;
  }
  if (draft) {
    data.draft = true;
  }
  return data;
};

export const contentVariables = (
  id?: string,
  languageCode: string = 'en',
  draft?: boolean,
) => {
  const data: any = {
    id,
    locale: getAPILocale(languageCode),
  };
  if (draft) {
    data.draft = true;
  }
  return data;
};

export const GET_CONTENTS_SOFT_POPULATE = gql`
  query GetContents(
    $where: Content_where
    $limit: Int
    $page: Int
    $draft: Boolean
    $locale: LocaleInputType
    $sort: String
  ) {
    Contents(
      where: $where
      locale: $locale
      limit: $limit
      page: $page
      draft: $draft
      sort:$sort
    ) {
      docs {
        id
        title
        description
        slug
        type
        subType
        newsSources {
          title
          id
        }
        videoCardDescription
        videoCardTitle
        publishedAt
        createdAt
        updatedAt
        audioNarration
        duration
        primaryTopics {
          page {
            slug
            name
          }
        }
        author {
          id
          name
          profileUrl
          profilePicture {
            url
            type
            caption
            mimeType
            playlistJson
            thumbnailUrl
          }
        }
           otherAuthors {
          id
          name
          profileUrl
          profilePicture {
            url
            type
            caption
            mimeType
            playlistJson
            thumbnailUrl
          }
        }
        owner {
          id
          firstName
          lastName
        }
        coverImage {
          caption
         
          ${cropMediaFragment}
        }
        media {
          caption
          ${cropMediaFragment}
        }
        coverMedia {
           caption
          ${cropMediaFragment}
        }
        topics {
          title
          updatedAt
          menu {
            secondaryMenuItems {
              name
              slug
              id
            }
            primaryMenuItems {
              name
              slug
              id
            }
          }
        }
        relatedContent {
          id
          body
        }
        reviewer {
          firstName
          lastName
          picture
        }
        readingTime {
          text
        }
        websiteBlockDetails {
          contentMetadata {
            id
            platform
            title
            description
            authorAvatarURL {
              url
              caption
              playlistJson
            }
            authorName
            liveStream {
              title
              description
              streamUrl
            }
            medias {
              media {
                caption
                ${cropMediaFragment}
              }
            }
          }
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;

export const GET_FULL_CONTENTS = gql`
  query GetContents(
    $where: Content_where
    $limit: Int
    $page: Int
    $draft: Boolean
    $locale: LocaleInputType
    $sort: String
  ) {
    Contents(
      where: $where
      locale: $locale
      limit: $limit
      page: $page
      draft: $draft
      sort:$sort
    ) {
      docs {
        id
        body
        title
        description
        slug
        type
        subType
        isFeatured
        newsSources {
          title
          id
        }
        videoCardDescription
        videoCardTitle
        publishedAt
        createdAt
        updatedAt
        audioNarration
        duration
        primaryTopics {
          page {
            slug
            name
          }
        }
        author {
          id
          name
          profileUrl
          profilePicture {
            url
            type
            caption
            mimeType
            thumbnailUrl
          }
        }
        otherAuthors {
          id
          name
          profileUrl
          profilePicture {
            url
            type
            caption
            mimeType
            thumbnailUrl
          }
        }
        owner {
          id
          firstName
          lastName
        }
        coverImage {
          caption
          source {
                   title
                }
          ${cropMediaFragment}
        }
        media {
          caption
          ${cropMediaFragment}
        }
        coverMedia {
           caption
          ${cropMediaFragment}
        }
        topics {
          title
          updatedAt
          menu {
            secondaryMenuItems {
              name
              slug
              id
            }
            primaryMenuItems {
              name
              slug
              id
            }
          }
        }
        relatedContent {
          id
          body
        }
        reviewer {
          firstName
          lastName
          picture
        }
        readingTime {
          text
        }
        websiteBlockDetails {
          contentMetadata {
            id
            platform
            title
            description
            authorAvatarURL {
              url
              caption
              playlistJson
            }
            authorName
            liveStream {
              title
              description
              streamUrl
            }
            medias {
              media {
                caption
                ${cropMediaFragment}
              }
            }
          }
        }
       
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;

export const GET_SITE_MAP_ARTICLECONTENTS = gql`
  query GetContents(
    $where: Content_where
    $limit: Int
    $page: Int
    $draft: Boolean
    $locale: LocaleInputType
  ) {
    Contents(
      where: $where
      locale: $locale
      limit: $limit
      page: $page
      draft: $draft
    ) {
      docs {
        id
        title
        slug
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;

export const GET_CONTENT = gql`
  query GetContent($id: String!, $locale: LocaleInputType, $draft: Boolean) {
    Content(id: $id, locale: $locale, draft: $draft) {
      id
      body
      title
      description
      slug
      type
      subType
      videoCardDescription
      videoCardTitle
      publishedAt
      createdAt
      updatedAt
      audioNarration
      duration
      author {
        id
        name
        profileUrl
        profilePicture {
          url
          type
          caption
          mimeType
          playlistJson
          thumbnailUrl
        }
      }
           otherAuthors {
          id
          name
          profileUrl
          profilePicture {
            url
            type
            caption
            mimeType
            playlistJson
            thumbnailUrl
          }
        }
      owner {
        id
        firstName
        lastName
      }
      coverImage {
        caption
        ${cropMediaFragment}
      }
      media {
        caption
        ${cropMediaFragment}
      }
      coverMedia {
         caption
          ${cropMediaFragment}
      }
      topics {
        title
        updatedAt
        menu {
          secondaryMenuItems {
            name
            slug
            id
          }
          primaryMenuItems {
            name
            slug
            id
          }
        }
      }

      relatedContent {
        id
        body
      }
      reviewer {
        firstName
        lastName
        picture
      }
      primaryTopics {
        title
        id
      }
      secondaryTopics {
        title
        id
      }
      readingTime {
        text
      }
      websiteBlockDetails {
        contentMetadata {
          id
          platform
          title
          description
          authorAvatarURL {
            url
            caption
            playlistJson
          }
          authorName
          liveStream {
            title
            description
            streamUrl
          }
          medias {
            media {
              caption
              ${cropMediaFragment}
            }
          }
        }
      }
    }
  }
`;
