import gqlClient from '../gql';
import { GET_PAGES, pagesVariables } from '../graphql';
import {
  Block,
  BlockElement,
  Content,
  CustomPage,
  PageResponse,
  Section,
} from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';

export const getTopicPage = async (
  slug: string,
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    // @ts-ignore
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        slug,
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    if (!pageData) return undefined;
    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getHomePage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_home',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getOpinionPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_opinion',
        pageLayoutIdentifier: 'default_layout_opinion_home',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getOpinionDetailPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_opinion',
        pageLayoutIdentifier: 'default_layout_opinion_detail',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getFeaturesPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_features',
        pageLayoutIdentifier: 'default_layout_features_home',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getFeaturesDetailPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_features',
        pageLayoutIdentifier: 'default_layout_features_detail',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getSectionFromPage = (
  page: CustomPage | undefined,
  identifier: Section['sectionLayoutIdentifier'],
): Section | undefined => {
  if (!page?.sections) return;
  const section = page?.sections?.find(section => {
    const { sectionLayoutIdentifier } = section as Section;
    return sectionLayoutIdentifier === identifier;
  }) as Section;

  if (!section?.blocks?.length) return;
  section.blocks = section?.blocks?.filter(
    block =>
      ((block as Block).blockLayoutIdentifier === 'listen_radio_large' &&
        (block as Block).blockElements?.filter(b => b.radioPlaylist).length) ||
      (block as Block).blockElements?.some(
        element =>
          (element as BlockElement).liveCoverage?.liveCoverageRecords?.length ||
          (element as BlockElement).liveCoverage?.liveCoverageRecords?.length ||
          (element as BlockElement).coverMedia?.length ||
          (element as BlockElement).tvShow ||
          (element as BlockElement).liveStream?.streamUrl ||
          (element as BlockElement).content,
      ),
  ) as Block[];
  if (!section?.blocks?.length) return;
  return section;
};

export const getBlockFromSection = (
  section: Section | undefined,
  identifier: Block['blockLayoutIdentifier'],
): Block | undefined => {
  return section?.blocks?.find(block => {
    const { blockLayoutIdentifier } = block as Block;
    return blockLayoutIdentifier === identifier;
  }) as Block | undefined;
};

export const getBlocksFromSection = (
  section: Section | undefined | null,
): Block[] | undefined => {
  return section?.blocks?.filter(block => {
    return typeof block === 'object';
  });
};

export const getContentsFromBlock = (item?: Block): Content[] | undefined => {
  const elements = item?.blockElements as BlockElement[] | undefined;
  return elements
    ?.filter(element => !!element.content)
    .map(element => {
      return element.content as Content;
    });
};

export const getContentsFromBlocks = (
  blocks?: Block[] | null | undefined,
): Content[] | undefined => {
  return blocks?.reduce<Content[]>(
    (acc, item) => [...acc, ...getContentsFromBlock(item)!],
    [],
  );
};

export const getContentIdsFromSections = (
  sections: (Section | undefined)[],
) => {
  return sections
    .filter(section => !!section)
    .map(section => {
      const blocks = getBlocksFromSection(section);
      return getContentsFromBlocks(blocks)?.map(content => content.id) ?? [];
    })
    .reduce((all, nextArr) => {
      return [...all, ...nextArr];
    }, [] as string[]);
};

export const getVideoLandingPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_video',
        pageLayoutIdentifier: 'default_layout_video_home',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getListenPage = async (
  channelId?: string,
  languageCode?: string,
): Promise<CustomPage | undefined> => {
  try {
    const pageResponse = await gqlClient.query<PageResponse>({
      query: GET_PAGES,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_listen',
        pageLayoutIdentifier: 'default_layout_listen_home',
        channelId,
        languageCode,
      }),
    });
    const pageData = pageResponse?.data;
    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};
